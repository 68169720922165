import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../components/global/layout/layout';
import Slice from '../../components/global/slice/slice';

export default ({ data }) => {
    return (
        <Layout title={data.prismicPage.data.meta_title.text}
                description={data.prismicPage.data.meta_description.text}
                image={data.prismicPage.data.meta_image.url}>
          <Slice slices={data.prismicPage.data.body}/>
        </Layout>
    )
}

export const pageQuery = graphql`
  query PageByUid($uid: String!) {
    prismicPage(uid: {eq: $uid}) {
    data {
      meta_title {
        text
      }
      meta_image {
        url
      }
      meta_description {
        text
      }
      body {
        __typename
        ... on PrismicPageBodySchlagworteText {
          primary {
            bulletpoints {
              raw
            }
            text {
              html
            }
          }
        }
        ... on PrismicPageBodyBilderLinks {
          items {
            image {
              url
            }
            link {
              text
            }
            text {
              text
            }
          }
        }
        ... on PrismicPageBodyBild {
          primary {
            color
            image {
              alt
              url
            }
            link {
              text
            }
            link_text {
              text
            }
            row_1 {
              text
            }
            row_2 {
              text
            }
            row_3 {
              text
            }
          }
        }
        ... on PrismicPageBodyTextabschnitte {
          items {
            text {
              html
            }
            heading {
              text
            }
          }
        }
        ... on PrismicPageBodyText {
          primary {
            text {
              html
            }
          }
        }
        ... on PrismicPageBodyBildLink {
          primary {
            title {
              text
            }
            text {
              text
              html
            }
            link_text {
              text
            }
            link {
              text
            }
            image {
              alt
              url
            }
          }
        }
        ... on PrismicPageBodyPersonenBereich {
          primary {
            heading {
              text
            }
          }
          items {
            text {
              html
              text
            }
            position {
              text
              raw
            }
            lastname {
              text
            }
            image {
              url
              alt
            }
            firstname {
              text
            }
            email {
              text
            }
            phone {
              text
            }
          }
        }
        ... on PrismicPageBodyBildergalerie {
          items {
            image {
              url
              alt
            }
          }
        }
        ... on PrismicPageBodyProjektTeaser {
          items {
            use_video
            video_link {
              text
            }
            video_thumbnail {
              alt
              url
            }
            image {
              alt
              url
            }
            link {
              text
            }
            link_text {
              text
            }
            text {
              html
            }
            title {
              text
            }
          }
        }
        ... on PrismicPageBodyZitat {
          primary {
            author {
              text
            }
            image {
              alt
              url
            }
            text {
              html
            }
          }
        }
        ... on PrismicPageBodyVorteile {
          primary {
            heading {
              text
            }
            image {
              url
              alt
            }
            items {
              raw
            }
          }
        }
        ... on PrismicPageBodyBildHeader {
          primary {
            color
            image {
              alt
              url
            }
            row_1 {
              text
            }
            row_2 {
              text
            }
            row_3 {
              text
            }
          }
        }
        ... on PrismicPageBodyUberschriftUndText {
          primary {
            text {
              html
            }
            heading {
              text
            }
            id {
              text
            }
          }
        }
        ... on PrismicPageBodyProzessKarten {
          items {
            text {
              html
            }
            title {
              text
            }
            link {
              text
            }
          }
        }
        ... on PrismicPageBodyIframe {
            primary {
                html {
                    text
                }
                responsive
            }
        }
      }
    }
  }
  }
`
